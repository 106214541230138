<template>
  <div class="ion-no-padding column">
    <ion-skeleton-text
      class="my-2"
      animated
      style="width: 50%; height: 20px; border-radius: 6px"
    ></ion-skeleton-text>
    <ion-row class="ion-align-items-start">
      <ion-col class="mr-2 ion-no-padding" size="auto">
        <ion-skeleton-text
          animated
          style="width: 16px; height: 16px; border-radius: 100%"
        ></ion-skeleton-text>
      </ion-col>
      <ion-col class="ion-no-padding">
        <div class="column">
          <ion-skeleton-text animated style="width: 80%; height: 14px"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 50%; height: 14px"></ion-skeleton-text>
        </div>
      </ion-col>
    </ion-row>
    <ion-skeleton-text
      class="mr-2 my-2"
      animated
      style="width: 100%; height: 40px; border-radius: 10px"
    ></ion-skeleton-text>
    <div class="my-3">
      <ion-skeleton-text class="mx-2 my-2" animated style="width: 40%; height: 18px"></ion-skeleton-text>
    </div>
    <div v-for="(item, index) in 4" :key="index">
      <ion-row class="ion-align-items-start">
        <ion-col class="mr-2 ion-no-padding" size="auto">
          <ion-thumbnail class="thumbnail ml-2 mb-2">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-thumbnail>
        </ion-col>
        <ion-col class="column ion-no-padding">
          <ion-skeleton-text animated style="width: 80%; height: 14px"></ion-skeleton-text>
          <ion-row class="row mt-1">
            <ion-col class="column">
              <ion-skeleton-text animated style="width: 50%; height: 14px"></ion-skeleton-text>
            </ion-col>
            <ion-col class="column end">
              <ion-skeleton-text animated style="width: 30%; height: 14px"></ion-skeleton-text>
              <ion-skeleton-text animated style="width: 55%; height: 14px"></ion-skeleton-text>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>

    <ion-row class="row mt-4">
      <ion-col class="column">
        <ion-skeleton-text animated style="width: 50%; height: 14px"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 35%; height: 14px"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 70%; height: 18px"></ion-skeleton-text>
      </ion-col>
      <ion-col class="column end">
        <ion-skeleton-text animated style="width: 50%; height: 14px"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 35%; height: 14px"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 70%; height: 18px"></ion-skeleton-text>
      </ion-col>
    </ion-row>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.title {
  height: 16px;
}

.thumbnail {
  width: 65px;
  height: 65px;
}

ion-thumbnail {
  display: block;
}

ion-skeleton-text {
  border-radius: 4px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  justify-items: start;
}

.end {
  text-align: right;
  align-items: flex-end;
}
</style>
